import React from 'react';
import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
/*import { useLocation } from 'react-router-dom';*/
//import { ScrollRestoration } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import axios from "axios";
import { SEO } from './SEO';
import { NotFound } from './NotFound';

export const ProductosDetalle = ({ setMiCarrito, productosCategorias, notFound, setNotFound }) => {//productoXid, getProductosId, getIdProducto1

    const primeraLetraMayuscula = (cadena) => {
        return cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length))
    };

    const [productoXid, setProductoXid] = useState([]);


    const ref = useRef();
    const navigate = useNavigate();
    const { slug } = useParams();
    console.log("######")
    console.log(slug)




    const getProductoXid = (nuevoNombre) => {
        //alert(nuevoNombre);
        setProductoXid(nuevoNombre);
    }







    const getProductoId = async () => {
        //alert("::::::::::" + idProducto1 + " * " + categoria.id);

        try {
            const res = await axios.get(process.env.REACT_APP_URL_API + "/producto/" + slug);
            //alert("aqui2");

            console.log(res.data);
            //console.log(idProducto1);
            //console.log(idCategoria);
            getProductoXid(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
        } catch (error) {
            if (error.response.status == 404) {
                //alert("not found")
                //navigate('/producto/' + idProducto);
                setNotFound(true);
            }
            //console.log("$$$$$$$$$$$$$$$$$$$$$$$$$");
            //console.log(error);
            //console.log("$$$$$$$$$$$$$$$$$$$$$$$$$");
        }
    };










    //const productos = productos1['productos'];
    /*
    console.log("*********");
    console.log(productos);
    console.log("//////////////");
    console.log(getProductosId);

    console.log(idProducto);*/
    //const productos = ref.current;
    function Holis() {
        alert("yusa");
    }





    const handleClick = (nombre, precio, id, imagen, unidad) => {
        // 👇️ push to the end of the state array
        setMiCarrito(current => [...current, { id: id, nombre: nombre, imagen: imagen, precio: precio, cantidad: 1, precioTOT: precio, unidad: unidad }]);

        // 👇️ spread an array into the state array
        // setNames(current => [...current, ...['Carl', 'Delilah']]);

        // 👇️ push to the beginning of the state array
        // setNames(current => ['Zoey', ...current]);
    };



    const handleClick1 = (nombre, precio, id, imagen, unidad) => {
        // 👇️ push to the end of the state array
        setMiCarrito([{ id: id, nombre: nombre, imagen: imagen, precio: precio, cantidad: 1, precioTOT: precio, unidad: unidad }]);

        // 👇️ spread an array into the state array
        // setNames(current => [...current, ...['Carl', 'Delilah']]);

        // 👇️ push to the beginning of the state array
        // setNames(current => ['Zoey', ...current]);
    };


    useEffect(() => {
        //getIdProducto1(idProducto);
        console.log("------------")
        console.log(slug)
        //getProductosId();
        //window.scrollTo(0, 0);

        getProductoId();

    }, []);
    /*
        useEffect(() => {
    
    
        }, [productoXid]);
    */


    /*
    
        const { pathname } = useLocation();
    
        // Automatically scrolls to top whenever pathname changes
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
        
    */


    const filtered = productoXid.filter(producto => producto.publicar == 1);

    const nombreCategoria = (categorr) => {
        return productosCategorias.filter(x => x.id == categorr).map(y => { return (y.nombre) });
        //console.log(z);
        //setNombreCategoria(z)
    }

    const slugCategoria = (categorr) => {
        return productosCategorias.filter(x => x.id == categorr).map(y => { return (y.slug) });
        //console.log(z);
        //setNombreCategoria(z)
    }


    return (<>
        {notFound == true ?
            <NotFound />
            :
            <div className='pt-5 pb-5 pl-4 pr-4 fondo7 '>

                {
                    filtered.length > 0 ?
                        filtered.map((producto, index) =>
                            <div>
                                <SEO
                                    title={"Tienda Brando - " + nombreCategoria(productoXid[0].categoria).toString() + " - " + primeraLetraMayuscula(productoXid[0].nombre)}
                                    description={nombreCategoria(productoXid[0].categoria).toString() + " - " + primeraLetraMayuscula(productoXid[0].nombre)}
                                    url={"producto/" + productoXid[0].id}
                                />
                                <div className='row'>
                                    <div className='col-12 col-lg-8 p-0 text-center'>

                                        <div className='d-flex justify-content-center'>

                                            <div className='tamañoCarusel  '>
                                                <Carousel statusFormatter={(current, total) => `${current} de ${total}`}>
                                                    {
                                                        JSON.parse(producto.imagenes).map((imagen) =>
                                                            <div>
                                                                <img src={"../../imagenes/productos/" + producto.id + "/" + imagen} alt={producto.categoria + " " + producto.nombre} />
                                                            </div>
                                                        )
                                                    }
                                                </Carousel>

                                            </div>
                                        </div>

                                        {/* <img src={require("../imagenes/D_NQ_NP_610805-MLA26483815716_122017-O.webp")} class="img-fluid " alt="..." /> */}


                                    </div>
                                    <div className='col-12 col-lg-4 p-0 '>
                                        <div className='border w-100 p-3 pb-3 pt-4 fondo9'>
                                            <div className='text-center'>
                                                <h1 className='font-weight-bold'>{primeraLetraMayuscula(producto.nombre)}</h1>
                                                <h2>
                                                    <NumericFormat
                                                        value={producto.precio}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        displayType="text"
                                                        prefix={'$ '}
                                                    /> por {producto.nombre_unidad}</h2>
                                            </div>
                                            <div className='text-center pt-1'>
                                                <div className='row'>
                                                    <div className='col-12'> <Link to="/carrito" onClick={() => handleClick(producto.nombre, producto.precio, producto.id, JSON.parse(producto.imagenes)[0], producto.nombre_unidad)}><button type="button" class="btn btn-primary  btn-lg mb-3 mt-3">Agregar al carro</button></Link></div>
                                                </div>
                                            </div>




                                            <div className='pt-3'>

                                                <div className='d-block justify-content-center '>
                                                    <div className='caracteristicas p-0 '>
                                                        <div className='producto-detalle caracteristicas-titulo1 text-center pt-2 pb-2 font-weight-bold'>Características</div>
                                                        {producto.categoria == 1 &&//ceramicos
                                                            <>

                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Marca</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.marca)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Línea</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.linea)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Rinde</span></div>
                                                                    <div className='col-8 text-left'>{producto.rinde} m2.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Ancho</span></div>
                                                                    <div className='col-8 text-left'>{producto.ancho} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Largo</span></div>
                                                                    <div className='col-8 text-left'>{producto.largo} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Espesor</span></div>
                                                                    <div className='col-8 text-left'>{producto.espesor} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Calidad</span></div>
                                                                    <div className='col-8 text-left'>{producto.calidad}°</div>
                                                                </div>

                                                            </>
                                                        }
                                                        {producto.categoria == 2 &&//porcelanatos
                                                            <>

                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Marca</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.marca)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Línea</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.linea)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Rinde</span></div>
                                                                    <div className='col-8 text-left'>{producto.rinde} m2.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Ancho</span></div>
                                                                    <div className='col-8 text-left'>{producto.ancho} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Largo</span></div>
                                                                    <div className='col-8 text-left'>{producto.largo} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Espesor</span></div>
                                                                    <div className='col-8 text-left'>{producto.espesor} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Calidad</span></div>
                                                                    <div className='col-8 text-left'>{producto.calidad}°</div>
                                                                </div>

                                                            </>
                                                        }
                                                        {producto.categoria == 3 &&//pastina y pegamentos
                                                            <>

                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Marca</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.marca)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Línea</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.linea)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Rinde</span></div>
                                                                    <div className='col-8 text-left'>{producto.rinde} m2.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Peso</span></div>
                                                                    <div className='col-8 text-left'>{producto.peso} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Calidad</span></div>
                                                                    <div className='col-8 text-left'>{producto.calidad}°</div>
                                                                </div>

                                                            </>
                                                        }
                                                        {producto.categoria == 4 &&//vanitory
                                                            <>

                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Marca</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.marca)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Modelo</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.linea)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Altura</span></div>
                                                                    <div className='col-8 text-left'>{producto.altura} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Ancho</span></div>
                                                                    <div className='col-8 text-left'>{producto.ancho} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Profundidad</span></div>
                                                                    <div className='col-8 text-left'>{producto.profundidad} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Incluye griferia</span></div>
                                                                    <div className='col-8 text-left'>{producto.incluyeGriferia == 1 ? 'Si' : 'No'}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Incluye espejo</span></div>
                                                                    <div className='col-8 text-left'>{producto.incluyeEspejo == 1 ? 'Si' : 'No'}</div>
                                                                </div>

                                                            </>
                                                        }
                                                        {producto.categoria == 5 &&//griferia
                                                            <>

                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Marca</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.marca)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Modelo</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.linea)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Altura</span></div>
                                                                    <div className='col-8 text-left'>{producto.altura} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Ancho</span></div>
                                                                    <div className='col-8 text-left'>{producto.ancho} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Profundidad</span></div>
                                                                    <div className='col-8 text-left'>{producto.profundidad} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Material</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.material)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Lugar colocación</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.lugarColocacion)}</div>
                                                                </div>

                                                            </>
                                                        }
                                                        {producto.categoria == 6 &&//inodoros y bidets
                                                            <>

                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Marca</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.marca)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Modelo</span></div>
                                                                    <div className='col-8 text-left'>{primeraLetraMayuscula(producto.linea)}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Altura</span></div>
                                                                    <div className='col-8 text-left'>{producto.altura} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Ancho</span></div>
                                                                    <div className='col-8 text-left'>{producto.ancho} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Profundidad</span></div>
                                                                    <div className='col-8 text-left'>{producto.profundidad} cm.</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Incluye griferia</span></div>
                                                                    <div className='col-8 text-left'>{producto.incluyeGriferia == 1 ? 'Si' : 'No'}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-4 text-center'><span className='font-weight-bold'>Incluye tapa</span></div>
                                                                    <div className='col-8 text-left'>{producto.incluyeTapa == 1 ? 'Si' : 'No'}</div>
                                                                </div>

                                                            </>
                                                        }
                                                        {producto.descripcion.length > 0 &&

                                                            <div className='row'>
                                                                <div className='col-12 text-center'><span className='font-weight-bold'>Descripción</span></div>

                                                                <div className=' col-12 pl-4 pr-4 text-center'>{producto.descripcion}</div>
                                                            </div>


                                                        }


                                                    </div>
                                                </div>
                                            </div>

                                            {/* <button type="button" class="btn btn-light  btn-lg disabled">Agregado al carrito</button> */}
                                        </div>
















                                    </div>
                                </div>


                                <div className='text-center pt-4 pt-lg-0'>
                                    <button type="submit" onClick={() => { navigate('/productos/' + slugCategoria(producto.categoria)); }} class="btn btn-primary  btn-lg" >Volver</button>
                                </div>


                            </div>
                        )

                        :
                        <h1 className='text-center pt-5 pb-5'>No se encontro el producto.</h1>
                }



            </div >
        }
    </>
    )


    /*





                                        <div >
            <div><span className='font-weight-bold'>Marca:</span> {primeraLetraMayuscula(producto.marca)}</div>
            <div><span className='font-weight-bold'>Linea:</span> {primeraLetraMayuscula(producto.linea)}</div>
            <div><span className='font-weight-bold'>Rinde:</span> {producto.rinde} m2.</div>
            <div><span className='font-weight-bold'>Ancho:</span> {producto.ancho} cm.</div>
            <div><span className='font-weight-bold'>Largo:</span> {producto.largo} cm.</div>
            <div><span className='font-weight-bold'>Espesor:</span> {producto.espesor} cm.</div>
            <div><span className='font-weight-bold'>Calidad:</span> {producto.calidad}°</div>
        </div>







        <div>id: {productos[0].id}</div>
        <div>nombre:  {productos[0].nombre}</div>
        <div>precio:  {productos[0].precio}</div>
        <div>categoria:  {productos[0].categoria}</div>











        <div>

            {
                productos.map((producto) =>
                    <div>
                        <div>id: {producto.id}</div>
                        <div>nombre: {producto.nombre}</div>
                        <div>precio: {producto.precio}</div>
                        <div> categoria: {producto.categoria}</div>
                        {producto.categoria == 3//si es categoria 1
                            ?
                            <div>
                                <div>marca: {producto.marca}</div>
                                <div>modelo: {producto.modelo}</div>
                                <div>peso: {producto.peso}</div>
                            </div>
                            : null
                        }
                        {producto.categoria == 2//si es categoria 1
                            ?
                            <div>
                                <div>ancho: {producto.ancho}</div>
                                <div>largo: {producto.largo}</div>

                            </div>
                            : null
                        }


                    </div>
                )
            }

        </div>
        */
}
//export default ProductosDetalle;