import { useForm } from 'react-hook-form';
//import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCartShopping, faPhone, faLocationDot, faCheckCircle, faCircle, faCheck, } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import GoogleMapReact from 'google-map-react';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
//import Mailjet from 'node-mailjet';
import axios from "axios";
import { RevealOnScroll } from './RevealOnScroll';
import confetti from "canvas-confetti";
import { SEO } from './SEO';

export const Contacto = () => {
    var [mensajeEnviado, setMensajeEnviado] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const confites = () => {
        setTimeout(() => {
            confetti({
                particleCount: 60,
                startVelocity: 22,
                spread: 90
                /*
                origin: {
                x: Math.random(),
                // since they fall down, start a bit higher than random
                y: Math.random() - 0.2
                }
                angle: 120,
                */
            });
        }, 1000);
    }
    const getMensajeEnviado = (nuevo) => {
        //sucursales1 = nuevo;
        setMensajeEnviado(nuevo);
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Opcionalmente, puedes cambiar a 'auto' para un desplazamiento instantáneo
        });
    }
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    // Function that displays a success toast on bottom right of the page when form submission is successful
    const toastifySuccess = () => {
        toast('Form sent!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback success',
            toastId: 'notifyToast'
        });
    };



    // Function called on submit that uses emailjs to send email of valid contact form
    const onSubmit = async (data) => {

        //        alert("enviando email2");


        console.log(data)


        await axios
            .post(process.env.REACT_APP_URL_API + "/contactar/", data)
            .then(({ data }) => {
                reset();
                getMensajeEnviado(true);
                setIsLoading(false);
                confites();
                //window.scrollTo(0, 0);
                /*
                console.log("++++++++++++")
                console.log(data)*/
                //idReturn = data.id;
                //copiar de temp a prodcutos/id
                //borrar temp
                // todo ok toast susecc
            })//toast.success(data)
            .catch(({ data }) => toast.error(data));


        // const Mailjet = require('node-mailjet');



        /*
                // Destrcture data object
                const { nombre, apellido, email, mensaje } = data;
                try {
                    const templateParams = {
                        nombre,
                        apellido,
                        email,
                        mensaje
                    };
                    /*
                                await emailjs.send(
                                    process.env.REACT_APP_SERVICE_ID,
                                    process.env.REACT_APP_TEMPLATE_ID,
                                    templateParams,
                                    process.env.REACT_APP_USER_ID
                                );
                    








        
        reset();
        getMensajeEnviado(true);
        toastifySuccess();
    } catch (e) {
        alert("nop");
        console.log(e);
    }

        */
    };

    const AnyReactComponent = ({ text }) => <div className='mapa'><FontAwesomeIcon icon={faLocationDot} className='icono-mapa' /><span className='texto-mapa'>{text}</span></div>;

    const defaultProps = {
        center: {
            lat: -34.76070840226209,
            lng: -58.29422481684238
        },
        zoom: 17
    };

    return (
        <>
            <SEO
                title="Tienda Brando - Contacto"
                description="Sucursales: Jazmin, Teléfono: 11 4146-3604 / 4200-1429, Dirección: Cno. Gral. Manuel Belgrano 3748. Quilmes, Buenos aires, Argentina."
                url="contacto"

            />


            <div className=' fondo7 pt-5 pb-5 pl-4 pr-4'>
                <RevealOnScroll>
                    <h3 className='text-center pb-4 fx1'>Contactanos</h3>
                </RevealOnScroll>





                {mensajeEnviado == false
                    ?





                    <div className='row '>
                        <div className='col-12 col-lg-6 p-0 pr-lg-4'>
                            <div>
                                <div className="fondo8 p-4">

                                    <div className=" d-flex justify-content-center">
                                        <div className=' d-inline-flex'>
                                            <div className=' '>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faLocationDot} /></span><div><span class="font-weight-bold">Dirección:</span> Cno. Gral. Manuel Belgrano 3748. <br></br>Quilmes, Buenos aires, Argentina.</div></div>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faPhone} /></span><div><span class="font-weight-bold">Teléfono:</span> <span>11 4146-3604</span> <spa>/</spa> <span>4200-1429</span></div></div>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faEnvelope} /></span><div><span class="font-weight-bold">Email:</span><span className='text-break'> Revestimientojazminquilmes@gmail.com</span></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h1 className='pt-4 text-center pb-2'>Envíanos un mensaje</h1>



                                <div className='contactForm'>
                                    <form id='contact-form' onSubmit={handleSubmit((data) => {
                                        onSubmit(data);
                                        setIsLoading(true);
                                    })} noValidate>

                                        <div className='row formRow'>
                                            <div className='col-12 col-lg-6 p-0 pb-3 pr-lg-1'>
                                                Nombre
                                                <input
                                                    type='text'
                                                    name='nombre'
                                                    {...register('nombre', {
                                                        required: true
                                                    })}
                                                    className={errors.nombre ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                    placeholder='Andrés'
                                                    role="presentation"
                                                    autoComplete="off"
                                                ></input>
                                                {errors.nombre && <span className='errorMessage'>Ingresa tu nombre.</span>}
                                            </div>
                                            <div className='col-12 col-lg-6 p-0 pb-3 pl-lg-1'>
                                                Apellido
                                                <input
                                                    type='text'
                                                    name='apellido'
                                                    {...register('apellido', {
                                                        required: true
                                                    })}
                                                    className={errors.apellido ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                    placeholder='López'
                                                    role="presentation"
                                                    autoComplete="off"
                                                ></input>
                                                {errors.apellido && <span className='errorMessage'>Ingresa tu apellido.</span>}
                                            </div>
                                        </div>
                                        <div className='row formRow '>
                                            <div className='col-12 col-lg-6 p-0 pb-3 pr-lg-1'>
                                                Email
                                                <input
                                                    type='email'
                                                    name='email'
                                                    {...register('email', {
                                                        required: true,
                                                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                                    })}
                                                    className={errors.email ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                    placeholder='AndresLopez@gmail.com'
                                                    role="presentation"
                                                    autoComplete="off"
                                                ></input>
                                                {errors.email && (
                                                    <span className='errorMessage'>Ingresa un email.</span>
                                                )}
                                            </div>
                                            <div className='col-12 col-lg-6 p-0 pb-3  pl-lg-1'>
                                                Teléfono
                                                <input
                                                    type='text'
                                                    name='telefono'
                                                    {...register('telefono', {
                                                        required: true
                                                    })}
                                                    className={errors.telefono ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                    placeholder='11 1234 5678'
                                                    role="presentation"
                                                    autoComplete="off"
                                                ></input>
                                                {errors.telefono && <span className='errorMessage'>Ingresa tu telefono.</span>}
                                            </div>
                                        </div>
                                        <div className='row formRow'>
                                            <div className='col-12 p-0'>
                                                Mensaje
                                                <textarea
                                                    rows={3}
                                                    name='mensaje'
                                                    {...register('mensaje', {
                                                        required: true
                                                    })}
                                                    className={errors.mensaje ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                    placeholder='Hola, quiero preguntarles...'
                                                    role="presentation"
                                                    autoComplete="off"
                                                ></textarea>
                                                {errors.mensaje && <span className='errorMessage'>Ingresa tu mensaje.</span>}
                                            </div>
                                        </div>
                                        <div className='text-center pt-4 '>
                                            <button className=' btn btn-primary btn-lg' disabled={isLoading} type='submit'>
                                                {isLoading ? <><span class="spinner-border spinner-border-sm"></span> Enviando</> : 'Enviar'}
                                            </button>
                                        </div>

                                    </form>
                                </div>

                            </div>

                        </div>
                        <div className='col-12 col-lg-6 p-0 pt-5 pt-lg-0'>

                            <div className='mapa-contacto'> {/* 100vh*/}
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyDs7rVJqAEGe45R2_P0RfM_2uA8CrQDc60" }}
                                    defaultCenter={defaultProps.center}
                                    defaultZoom={defaultProps.zoom}
                                >

                                    <AnyReactComponent
                                        lat={-34.760710103357546}
                                        lng={-58.29423171885013}
                                        text="Jazmín"
                                    />
                                </GoogleMapReact>
                            </div>

                        </div>
                    </div>




                    :
                    <div>



                        <div className='text-center pt-3'>

                            <div className='pb-4'>
                                <span className="fa-layers fa-9x">
                                    <FontAwesomeIcon icon={faCircle} color="green" transform="grow-5" />
                                    <FontAwesomeIcon icon={faCheck} inverse />
                                </span>
                            </div>


                            <div className='comprastes font-weight-bold pt-2 '>¡Mensaje enviado!</div>

                            <div className='pb-5'>En breve nos pondremos en contacto con usted.</div>
                            <div>

                                <button onClick={() => { getMensajeEnviado(false) }} className=' btn btn-primary btn-lg' type='submit'>
                                    Volver
                                </button>
                            </div>



                        </div>

                    </div>
                }





            </div >
        </>
    );
};