import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
const FormContainer = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px;
`;
const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  width: 140px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  background-color:#0b0e11;
  color:white;
`;
const Label = styled.label``;
const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #2c73d2;
  color: white;
  height: 42px;
`;
const FormLinks = ({ getUsers, onEdit, setOnEdit, linksCategorias, categoria1, cambiarCategoria }) => {
  const ref = useRef();
  const NombreCategoria = linksCategorias
    .filter(function (task1) {
      return task1.id == categoria1;
    })
    .map(obj => obj.nombre);
  useEffect(() => {
    if (onEdit) {
      document.getElementById("sara").focus();
      const user = ref.current;
      user.nombre.value = onEdit.nombre;
      user.link.value = onEdit.link;
      cambiarCategoria(onEdit.idCategoria);
    }
  }, [onEdit]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = ref.current;
    if (
      !user.nombre.value ||
      !user.link.value
    ) {
      return toast.warn("Completa todos los campos!");
    }
    if (onEdit) {
      await axios
        .put(process.env.REACT_APP_URL_API + "/" + onEdit.id, {
          nombre: user.nombre.value,
          link: user.link.value,
          idCategoria: categoria1,
        })
        .then(({ data }) => toast.success(data))
        .catch(({ data }) => toast.error(data));
    } else {
      await axios
        .post(process.env.REACT_APP_URL_API + "", {
          nombre: user.nombre.value,
          link: user.link.value,
          idCategoria: categoria1,
        })
        .then(({ data }) => toast.success(data))
        .catch(({ data }) => toast.error(data));
    }
    user.nombre.value = "";
    user.link.value = "";
    cambiarCategoria(0);
    setOnEdit(null);
    getUsers();
  };
  return (
    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit} className="color2 mt-3">
        <InputArea>
          <Label>Nombre</Label>
          <Input id="sara" name="nombre" onLoad={e => { console.log("el valor guardado es: " + e.value) }} role="presentation" autoComplete="off" />
        </InputArea>
        <InputArea>
          <Label>Link</Label>
          <Input id="connor" name="link" role="presentation" autoComplete="off" />
        </InputArea>
        <InputArea>
          <Label>Categoria</Label>
          <div class="dropdown pr-1 ">
            <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
              {NombreCategoria}
            </button >
            <div class="dropdown-menu">
              {linksCategorias.map((item) => (
                <a class="dropdown-item" onClick={(e) => cambiarCategoria(item.id)}>{item.nombre}</a>
              ))}
            </div>
          </div>
        </InputArea >
        <Button type="submit">GUARDAR</Button>
      </FormContainer >
    </div >
  );
};
export default FormLinks;