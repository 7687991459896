import React from "react";
import axios from "axios";
import styled from "styled-components";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const Table = styled.table`
  width: 100%;
  padding: 20px;
  max-width: 1120px;
  margin-top: 1rem;
  margin-bottom:1rem;
  word-break: break-all;

`;
export const Thead = styled.thead``;
export const Tbody = styled.tbody``;
export const Tr = styled.tr``;
export const Th = styled.th`
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none"}
  }
`;
export const Td = styled.td`
  padding-top: 15px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};

  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none"}
  }
`;
const GridCategorias = ({ linksCategorias, setUsers1, setOnEdit1 }) => {
    //alert("todo el tiempo carga?");
    const handleEdit = (item) => {
        setOnEdit1(item);
    };
    const handleDelete = async (id) => {
        handleClose();
        await axios
            .delete(process.env.REACT_APP_URL_API + "/linksCategorias/" + id)
            .then(({ data }) => {
                const newArray = linksCategorias.filter((user1) => linksCategorias.id !== id);
                setUsers1(newArray);
                toast.success(data);
            })
            .catch(({ data }) => toast.error(data));
        setOnEdit1(null);
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        //alert(id);
        cambiarID(id);
        setShow(true);
    };
    const [ID1, setID] = useState(null);
    const cambiarID = (nuevoID) => {
        setID(nuevoID);
    }
    return (
        <div>
            {/* closeButton */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Borrar</Modal.Title>
                </Modal.Header>
                <Modal.Body>esta seguro que desea borrar?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={() => handleDelete(ID1)} >
                        Borrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Table className="color2">
                <Thead>
                    <Tr className="aqui">
                        <Th>Nombre</Th>
                        <Th></Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {linksCategorias.map((item, i) => (
                        <Tr key={i}>
                            <Td width="30%">{item.nombre}</Td>
                            <Td alignCenter width="5%">
                                <FaEdit onClick={() => handleEdit(item)} />
                            </Td>
                            <Td alignCenter width="5%">
                                <FaTrash onClick={() => handleShow(item.id)} />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </div>
    );
};
export default GridCategorias;
