import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { ProductosListar } from './ProductosListar';
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast, faCreditCard, faCertificate, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { RevealOnScroll } from './RevealOnScroll';


export const Home = ({ productos, getProductos, getIdProducto1, productosCategorias, getCategoria, categoria, notFound, setNotFound, paginaActual, setPaginaActual }) => {

  console.log("****");
  console.log(categoria);

  setPaginaActual(1);

  const slugCategoria = (valor) => {
    //console.log("%" + valor)
    //sconsole.log("%" + productosCategorias)

    return productosCategorias.filter(x => x.id == valor).map(y => { return (y.slug) });
    //return "categoriaaa"
  };


  /*
  useEffect(() => {
    console.log("#####");
    getCategoria({ id: 1, nombre: "ceramicos" });
    console.log(categoria);
    alert("cada vez que entro a home?=");


  }, []);

*/
  return (<>
    <div>
      <div className=''>

        <div className='pl-3 pr-3 pb-4 fondo1'>

          <RevealOnScroll>
            <h3 className='pl-3 pr-3 pt-5 pb-4 text-center fx1'>Productos en oferta</h3>
          </RevealOnScroll>




          <ProductosListar productos={productos} getProductos={getProductos} getIdProducto1={getIdProducto1} getCategoria={getCategoria} notFound={notFound} setNotFound={setNotFound} productosCategorias={productosCategorias} paginaActual={paginaActual} />





          <div className='text-center'>
            <Link to="/productos"><button type="button" class="btn btn-primary  btn-lg mb-3 mt-3">Ver todos los productos</button></Link>
          </div>


        </div>

        <div className='fondo2 pb-4 pl-3 pr-3'>

          <div className=''>
            <RevealOnScroll>
              <h3 className='pl-3 pr-3 pb-4 pt-5 text-center fx1'>Categorias</h3>
            </RevealOnScroll>

            <div class="row row-cols-1 row-cols-md-3 justify-content-center pl-0 pr-0">

              {productosCategorias.map((item) => (
                <div className='col mb-4 '>
                  <Link to={"/productos/" + slugCategoria(item.id)} >
                    <div class="card bg-dark  box  profile-link">
                      <img src={"../imagenes/categorias/" + item.imagen} className="profile-pic card-img  " alt={item.nombre} />
                      <div class="card-img-overlay text-center d-flex align-items-center justify-content-center">
                        <h2 class="card-title font-weight-bold titulo-categorias">{item.nombre}</h2>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}


            </div>






          </div>

        </div>

      </div>
      <div className='row fondo3 text-center pt-5 pl-4 pr-4 pb-5'>
        <div className='col-12 col-sm-6 col-md-3 pb-5 pb-md-0'>

          <div className='pb-2 titulo2'> Envios a domicilio</div>
          <div><FontAwesomeIcon icon={faTruckFast} size='3x' className="ico-col2" /></div>

        </div>
        <div className='col-12 col-sm-6 col-md-3  pb-5 pb-md-0'>

          <div className='pb-2 titulo2'>Paga en cuotas</div>
          <div>  <FontAwesomeIcon icon={faCreditCard} size='3x' className="ico-col2" /></div>
        </div>
        <div className='col-12 col-sm-6 col-md-3  pb-5 pb-sm-0 pb-md-0'>
          <div className='pb-2 titulo2'>Garantia</div>
          <div>     <FontAwesomeIcon icon={faCertificate} size='3x' className="ico-col2" /></div>


        </div>
        <div className='col-12 col-sm-6 col-md-3  pb-0 pb-md-0'>
          <div className='pb-2 titulo2'>Compra protegida</div>
          <div> <FontAwesomeIcon icon={faShieldAlt} size='3x' className="ico-col2" /></div>

        </div>

      </div>
    </div>
  </>
  )
}