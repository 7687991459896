import React from 'react';
import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

export const ProductosListar = ({ productos, getProductos, getIdProducto1, rango1, rango2, notFound, setNotFound, productosCategorias, paginaActual }) => {

    const primeraLetraMayuscula = (cadena) => {
        return cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length))
    };

    const slugCategoria = (valor) => {
        //console.log("%" + valor)
        //sconsole.log("%" + productosCategorias)

        return productosCategorias.filter(x => x.id == valor).map(y => { return (y.slug) });
        //return "categoriaaa"
    };




    const { slug, categoria } = useParams();

    //const productos1 = productos['productos'];
    //console.log(productos);
    useEffect(() => {
        getProductos();
        setNotFound(false);
    }, []);


    const cambiatetete = (id) => {
        console.log("cambcatete");
        getIdProducto1(id);
        //getIdCategoria(categoria);
    }

    let productosPaginacion = productos.slice(rango1, rango2);

    return (
        <div >

            <div className=' '>

                <div>
                    <div class="row row-cols-1 row-cols-md-3">
                        {
                            productosPaginacion
                                .filter(producto => producto.publicar == 1)
                                .map((producto) =>
                                    <div class="col mb-4 ">

                                        <div class="card h-100 box">
                                            <article>
                                                <Link to={"/productos/" + slugCategoria(producto.categoria) + "/" + producto.slug} onClick={() => { cambiatetete(producto.id) }} className="item_producto">


                                                    <img src={"../imagenes/productos/" + producto.id + "/" + JSON.parse(producto.imagenes)[0]} class="card-img-top" alt={producto.nombre} />
                                                    <div class="card-body">
                                                        <h1 class="card-title">{primeraLetraMayuscula(producto.nombre)}</h1>
                                                        <p class="card-text">
                                                            <NumericFormat
                                                                value={producto.precio}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                displayType="text"
                                                                prefix={'$ '}
                                                            />



                                                        </p>
                                                    </div>
                                                </Link>
                                            </article>
                                        </div>


                                    </div>

                                )
                        }
                    </div>

                </div>








            </div>
        </div>
    )
}

/*
                <div>
                    {
                        productos.map((producto) =>
                            <div>
                                <div>id: {producto.id}</div>
                                <div>nombre: {producto.nombre}</div>
                                <div>precio: {producto.precio}</div>
                                <div> categoria: {producto.categoria}</div>
                                <div><Link to={"/productos/" + producto.id} onClick={() => { cambiatetete(producto.id, producto.categoria) }}>ver mas</Link></div>
                            </div>
                        )
                    }
                </div>
*/