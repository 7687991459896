import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCartShopping, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from "react";
import { RevealOnScroll } from './RevealOnScroll';
import { SEO } from './SEO';

export const Sucursales = ({ sucursales, getSucursales }) => {

    const AnyReactComponent = ({ text }) => <div className='mapa'><FontAwesomeIcon icon={faLocationDot} className='icono-mapa' /><span className='texto-mapa'>{text}</span></div>;

    const defaultProps = {
        center: {
            lat: -34.78502057319106,
            lng: -58.315992435936565
        },
        zoom: 17
    };

    const defaultProps1 = {
        center: {
            lat: -34.76070840226209,
            lng: -58.29422481684238
        },
        zoom: 17
    };


    const defaultProps2 = {
        center: {
            lat: -34.7793866927133,
            lng: -58.31821969996293
        },
        zoom: 17
    };
    var [sucursales1, setSucursales1] = useState(1);


    const getSucursales1 = (nuevo) => {
        //sucursales1 = nuevo;
        setSucursales1(nuevo);
    }

    return (<>
        <SEO
            title="Tienda Brando - Sucursales"
            description="Sucursales: Jazmín, Quilmes, 11 4146-3604. Ceramicasa, San Francisco Solano, 11 3353-5971. Deposito, San Francisco Solano."
            url="sucursales"

        />


        <div className='pb-0 fondo7  pt-5 pb-5 pl-4 pr-4'>
            <RevealOnScroll>
                <h3 className='text-center pb-4 fx1'>Sucursales</h3>
            </RevealOnScroll>
            <div className='row'>

                <div className='col-lg-3 p-0'>

                    <div id="list-example" class="list-group text-center text-lg-left pb-3 pb-lg-0 pr-lg-3">
                        <a onClick={() => getSucursales1(1)} class="list-group-item list-group-item-action active" data-toggle="pill" aria-selected="false">Jazmín</a>
                        <a onClick={() => getSucursales1(0)} class="list-group-item list-group-item-action " data-toggle="pill" aria-selected="true">Ceramicasa</a>
                        <a onClick={() => getSucursales1(2)} class="list-group-item list-group-item-action" data-toggle="pill" aria-selected="false">Deposito</a>
                    </div>

                </div>
                <div className='col-lg-9 pl-0 pr-0 pb-0 pt-0'>

                    {sucursales1 == 0 &&
                        <div className='pt-0 pb-0 fondo8 '>




                            <div className='row p-4 '>
                                <div className='col-12 col-lg-7 p-0 pb-4 pr-lg-4 pb-lg-0 '>

                                    <h1 className=' text-center'>Ceramicasa</h1>
                                    <div className=" d-flex justify-content-center">
                                        <div className=' d-inline-flex'>
                                            <div className=' '>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faLocationDot} /></span><div><span class="font-weight-bold">Dirección:</span> Av. Juan Domingo Perón 751. <br></br>San Francisco Solano, Buenos aires, Argentina.</div></div>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faPhone} /></span><div><span class="font-weight-bold">Teléfono:</span> <span>11 3353-5971</span> <spa>/</spa> <span>5253-2300</span> </div></div>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faEnvelope} /></span><div><span class="font-weight-bold">Email:</span><span className='text-break'> Revestimientojazminquilmes@gmail.com</span></div></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 col-lg-5 p-0 align-self-center '>

                                    <img src={require("../imagenes/ceramicasa.png")} class="img-fluid " alt="..." />


                                </div>
                            </div>

                            <div className='row'>

                                <div style={{ height: '300px', width: '100%' }} className='col-12 pl-4 pr-4 pb-4 pt-0'> {/* 100vh*/}
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyDs7rVJqAEGe45R2_P0RfM_2uA8CrQDc60" }}
                                        defaultCenter={defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                    >

                                        <AnyReactComponent
                                            lat={-34.78501451523313}
                                            lng={-58.31599243594288}
                                            text="Ceramicasa"
                                        />
                                    </GoogleMapReact>
                                </div>

                            </div>

                        </div>
                    }
                    {sucursales1 == 1 &&
                        <div className='pt-0 pb-0 fondo8 '>




                            <div className='row p-4 '>
                                <div className='col-12 col-lg-7 p-0 pb-4 pr-lg-4 pb-lg-0 '>

                                    <h1 className=' text-center'>Jazmín</h1>
                                    <div className=" d-flex justify-content-center">
                                        <div className=' d-inline-flex'>
                                            <div className=' '>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faLocationDot} /></span><div><span class="font-weight-bold">Dirección:</span> Cno. Gral. Manuel Belgrano 3748. <br></br>Quilmes, Buenos aires, Argentina.</div></div>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faPhone} /></span><div><span class="font-weight-bold">Teléfono:</span> <span>11 4146-3604</span> <spa>/</spa> <span>4200-1429</span></div></div>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faEnvelope} /></span><div><span class="font-weight-bold">Email:</span><span className='text-break'> Revestimientojazminquilmes@gmail.com</span></div></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 col-lg-5 p-0 align-self-center '>

                                    <img src={require("../imagenes/jazmin.png")} class="img-fluid " alt="..." />


                                </div>
                            </div>

                            <div className='row'>

                                <div style={{ height: '300px', width: '100%' }} className='col-12 pl-4 pr-4 pb-4 pt-0'> {/* 100vh*/}
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyDs7rVJqAEGe45R2_P0RfM_2uA8CrQDc60" }}
                                        defaultCenter={defaultProps1.center}
                                        defaultZoom={defaultProps1.zoom}
                                    >

                                        <AnyReactComponent
                                            lat={-34.760710103357546}
                                            lng={-58.29423171885013}
                                            text="Jazmín"
                                        />
                                    </GoogleMapReact>
                                </div>

                            </div>

                        </div>
                    }
                    {sucursales1 == 2 &&
                        <div className='pt-0 pb-0 fondo8 '>




                            <div className='row p-4 '>
                                <div className='col-12 col-lg-7 p-0 pb-4 pr-lg-4 pb-lg-0 '>

                                    <h1 className=' text-center'>Deposito</h1>
                                    <div className=" d-flex justify-content-center">
                                        <div className=' d-inline-flex'>
                                            <div className=' '>
                                                <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faLocationDot} /></span><div><span class="font-weight-bold">Dirección:</span> Av. Tte. Gral. Donato Álvarez 3998. <br></br>San Francisco Solano, Buenos aires, Argentina.</div></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 col-lg-5 p-0 align-self-center '>

                                    <img src={require("../imagenes/deposito.png")} class="img-fluid " alt="..." />


                                </div>
                            </div>

                            <div className='row'>

                                <div style={{ height: '300px', width: '100%' }} className='col-12 pl-4 pr-4 pb-4 pt-0'> {/* 100vh*/}
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyDs7rVJqAEGe45R2_P0RfM_2uA8CrQDc60" }}
                                        defaultCenter={defaultProps2.center}
                                        defaultZoom={defaultProps2.zoom}
                                    >

                                        <AnyReactComponent
                                            lat={-34.7793866927133}
                                            lng={-58.31821969996293}
                                            text="Deposito"
                                        />
                                    </GoogleMapReact>
                                </div>

                            </div>

                        </div>
                    }


                </div>
            </div>




            {/*             <div class="row">
                <div class="col-3">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="nav-link active" id="v-pills-home-tab" data-toggle="pill" data-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Home</button>
                        <button class="nav-link" id="v-pills-profile-tab" data-toggle="pill" data-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                        <button class="nav-link" id="v-pills-messages-tab" data-toggle="pill" data-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Messages</button>
                        <button class="nav-link" id="v-pills-settings-tab" data-toggle="pill" data-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</button>
                    </div>
                </div>
                <div class="col-9">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">...</div>
                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...</div>
                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">...</div>
                        <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div>
                    </div>
                </div>
            </div>*/}



        </div>
    </>
    )
}