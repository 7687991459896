import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelopeCircleCheck, faCircle, faCheck, faXmark, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState, useRef } from "react";
import { useForm } from 'react-hook-form';
//import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from "axios";
import { NumericFormat } from 'react-number-format';
import { Helmet, HelmetProvider } from "react-helmet-async";
import confetti from "canvas-confetti";
import { RevealOnScroll } from './RevealOnScroll';



const primeraLetraMayuscula = (cadena) => {
    return cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length))
};

const Checkbox = ({ label, value, onChange }) => {
    return (
        <label>
            <input type="checkbox" checked={value} onChange={onChange} />
            {label}
        </label>
    );
};

const Checkbox1 = ({ label, value, onChange }) => {
    return (

        <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" checked={value} onChange={onChange} />


            <label class="custom-control-label" for="customSwitch1"></label>
        </div>

    );
};
export const Carrito = ({ checked, setChecked, ObjectLength, envioFlete, getEnvioFlete, MiCarrito, setMiCarrito, MiCarritoTOTAL, getMiCarrito }) => {
    const [isLoading, setIsLoading] = useState(false);
    var [estadoCarrito, setEstadoCarrito] = useState(0);
    const ref = useRef();
    const [ultimoId, setultimoId] = useState(0);

    const handleChange = () => {
        setChecked(!checked);
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const confites = () => {
        setTimeout(() => {
            confetti({
                particleCount: 60,
                startVelocity: 22,
                spread: 90
                /*
                origin: {
                x: Math.random(),
                // since they fall down, start a bit higher than random
                y: Math.random() - 0.2
                }
                angle: 120,
                */
            });
        }, 1000);
    }

    const getEstadoCarrito = (nuevo) => {
        //alert(nuevoNombre);
        setEstadoCarrito(nuevo);

        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Opcionalmente, puedes cambiar a 'auto' para un desplazamiento instantáneo
        });
        //window.scrollTo(0, 0);
    }

    // Function that displays a success toast on bottom right of the page when form submission is successful
    const toastifySuccess = () => {
        toast('Form sent!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback success',
            toastId: 'notifyToast'
        });
    };


    const onSubmit = async (e) => {
        let info;
        const venta = ref.current;
        //e.preventDefault();
        console.log(venta.nombre.value);
        if (checked == true) {
            info = {
                nombre: venta.nombre.value,
                apellido: venta.apellido.value,
                email: venta.email.value,
                telefono: venta.telefono.value,
                venta: JSON.stringify(MiCarrito),
                direccion: venta.direccion.value,
                numero: venta.numero.value,
                localidad: venta.localidad.value,
                piso: venta.piso.value,
                departamento: venta.departamento.value
            }
        } else {
            info = {
                nombre: venta.nombre.value,
                apellido: venta.apellido.value,
                email: venta.email.value,
                telefono: venta.telefono.value,
                venta: JSON.stringify(MiCarrito),
            }
        }
        //console.log(venta.direccion.value);
        await axios
            .post(process.env.REACT_APP_URL_API + "/ventas/", info)
            .then(({ data }) => {
                //alert(data);
                //console.log(data);
                reset();
                //toastifySuccess();
                getMiCarrito([]);
                setultimoId(data);
                getEstadoCarrito(2);
                confites();
                setIsLoading(false);

                //const ultimoId = ;
            })
            .catch(({ data }) => toast.error(data));
    };


    const deleteByIndex = index => {
        setMiCarrito(oldValues => {
            return oldValues.filter((_, i) => i !== index)
        })
    }


    const sumar = (precio, cantidad) => {
        //alert("yoyo");
        return precio * cantidad

    }
    const switchEnvioFlete = () => {
        //alert("holis");
        if (envioFlete == true) {
            getEnvioFlete(false)
        } else {
            getEnvioFlete(true)
        }
    }

    const updateByIndex = index => {
        //alert("yoy");

        const newState = MiCarrito.map((obj, index1) => {
            // 👇️ if id equals 2, update country property
            if (index1 === index) {
                return { ...obj, cantidad: obj.cantidad + 1, precioTOT: sumar(obj.precio, obj.cantidad + 1) };
            }

            // 👇️ otherwise return the object as is
            return obj;
        });



        setMiCarrito(newState);


    }


    const updateByIndex1 = index => {
        //alert("yoy");

        const newState = MiCarrito.map((obj, index1) => {
            // 👇️ if id equals 2, update country property
            if (index1 === index) {
                if (obj.cantidad == 1) {
                    //alert("0");
                } else {
                    return { ...obj, cantidad: obj.cantidad - 1, precioTOT: sumar(obj.precio, obj.cantidad - 1) };
                }

            }

            // 👇️ otherwise return the object as is
            return obj;
        });



        setMiCarrito(newState);


    }




    return (
        <>
            <Helmet>
                <title>Tienda Brando - Carrito</title>
            </Helmet>


            <div className='fondo7 pt-5 pb-5'>
                {/*estadoCarrito*/}
                <RevealOnScroll>
                    <h3 className='text-center pb-4 fx1'>Carro de compra</h3>
                </RevealOnScroll>
                {estadoCarrito === 0 &&
                    <div className='pl-3 pr-3 pl-lg-5 pr-lg-5'>
                        {ObjectLength() == 0
                            ?
                            <div class="d-flex justify-content-center m-5">
                                <div className='text-center fondo9 border fondo9 p-3 pl-4 pr-4 p-1  d-inline-flex '>No hay ningún producto en el carro de compra.</div>
                            </div>
                            :
                            <div>



                                <div className='bordes table'>
                                    <div className='row carrito-titulos-top borde-bottom'>
                                        <div className='col p-2'></div>
                                        <div className='col p-2'><span className='font-weight-bold'>Nombre</span></div>
                                        <div className='col p-2'><span className='font-weight-bold'>Cantidad</span></div>
                                        <div className='col p-2'><span className='font-weight-bold'>Unidad</span></div>
                                        <div className='col p-2'><span className='font-weight-bold'>Precio</span></div>
                                        <div className='col p-2'><span className='font-weight-bold'>Borrar</span></div>
                                    </div>


                                    {
                                        MiCarrito.map((MiCarro, index) =>
                                            <div className='row borde-bottom text-center text-lg-left'>
                                                <div className='col-12 col-lg-2 p-2'>
                                                    <div><img src={"../imagenes/productos/" + MiCarro.id + "/" + MiCarro.imagen} className="img-fluid" alt="..." /></div>
                                                </div>
                                                <div className='col-12 col-lg-2 p-2'>

                                                    <div>{primeraLetraMayuscula(MiCarro.nombre)}</div>
                                                </div>
                                                <div className='col-12 col-lg-2 p-2'>
                                                    <div className='carrito-titulos'><span className='font-weight-bold'>Cantidad</span></div>
                                                    <div>
                                                        <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                                                            <button className='btn btn-carrito ' onClick={() => updateByIndex1(index)}><FontAwesomeIcon icon={faMinus} /></button>

                                                            <div className='btn-carrito-cantidad'> {MiCarro.cantidad} </div>

                                                            <button className='btn  btn-carrito ' onClick={() => updateByIndex(index)}><FontAwesomeIcon icon={faPlus} /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-lg-2 p-2'>
                                                    <div className='carrito-titulos'><span className='font-weight-bold'>Unidad</span></div>
                                                    <div>{MiCarro.unidad}</div>
                                                </div>
                                                <div className='col-12 col-lg-2 p-2'>
                                                    <div className='carrito-titulos'><span className='font-weight-bold'>Precio</span></div>
                                                    <div>
                                                        <NumericFormat
                                                            value={MiCarro.precioTOT}
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            displayType="text"
                                                            prefix={'$ '}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-12 col-lg-2 p-2'>
                                                    <div className='carrito-titulos'><span className='font-weight-bold'>Borrar</span></div>
                                                    <div>
                                                        <button className='btn  btn-carrito ' onClick={() => deleteByIndex(index)}><FontAwesomeIcon icon={faXmark} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}



                                    <div className='row borde-bottom pt-3 pb-3'>
                                        <div className='col-12 col-lg-8 p-lg-2'><div className='text-center text-lg-right'>TOTAL:</div></div>
                                        <div className='col-12 col-lg-4 p-lg-2'>
                                            <div className='text-center text-lg-left'>
                                                <NumericFormat
                                                    value={MiCarritoTOTAL}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    prefix={'$ '}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 p-2'>

                                            <div >
                                                <div>Envio a domicilio:


                                                </div>
                                                <div className='d-flex pl-2'>
                                                    <div className='pr-1'>NO</div>
                                                    <div>
                                                        <Checkbox1 label="My Value" value={checked} onChange={handleChange} />
                                                    </div>
                                                    <div>SI</div>

                                                </div>
                                            </div>



                                            <div className='pl-2 pt-2 '>
                                                <div className='border fondo9 p-3 pl-4 pr-4 p-1 d-inline-flex'>
                                                    <div>
                                                        {checked == true
                                                            ?
                                                            <>
                                                                <div className=''>Precio del flete: $20.000 pesos (aproximado).</div>
                                                                <div className=''>*El precio del flete puede variar segun la zona de destino y el tamaño de la compra.</div>

                                                            </>
                                                            :
                                                            <div className=''>Pasas a retirar tu pedido.</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                                <div className='text-center pt-1 pb-3'>
                                    <button type="button" class="btn btn-primary btn-lg mt-3" onClick={() => { getEstadoCarrito(1) }}>Continuar compra</button>
                                </div>

                            </div>
                        }


                    </div>
                }

                {
                    estadoCarrito === 1 &&
                    <div className='pl-3 pr-3 pl-lg-5 pr-lg-5'>
                        <h3 className='text-left pb-1 '>Datos de compra</h3>





                        <div className='contactForm'>
                            <form id='contact-form' onSubmit={handleSubmit((data) => {
                                onSubmit(data);
                                setIsLoading(true);
                            })} noValidate ref={ref}>
                                <div className='pb-2'>
                                    <div className='row formRow'>
                                        <div className='col-12 col-lg-6  p-0 pb-3 pr-lg-1'>
                                            Nombre
                                            <input
                                                type='text'
                                                name='nombre'
                                                {...register('nombre', {
                                                    required: true
                                                })}
                                                className={errors.nombre ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                placeholder='Andrés'
                                                role="presentation"
                                                autoComplete="off"
                                            ></input>
                                            {errors.nombre && <span className='errorMessage'>Ingresa tu nombre.</span>}
                                        </div>
                                        <div className='col-12 col-lg-6 p-0 pb-3 pl-lg-1'>
                                            Apellido
                                            <input
                                                type='text'
                                                name='apellido'
                                                {...register('apellido', {
                                                    required: true
                                                })}
                                                className={errors.apellido ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                placeholder='López'
                                                role="presentation"
                                                autoComplete="off"
                                            ></input>
                                            {errors.apellido && <span className='errorMessage'>Ingresa tu apellido.</span>}
                                        </div>
                                    </div>
                                    <div className='row formRow'>
                                        <div className='col-12 col-lg-6 p-0 pb-3 pr-lg-1'>
                                            Email
                                            <input
                                                type='email'
                                                name='email'
                                                {...register('email', {
                                                    required: true,
                                                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                                })}
                                                className={errors.email ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                placeholder='AndresLopez@gmail.com'
                                                role="presentation"
                                                autoComplete="off"
                                            ></input>
                                            {errors.email && (
                                                <span className='errorMessage'>Ingresa un email.</span>
                                            )}
                                        </div>

                                        <div className='col-12 col-lg-6 p-0 pb-3 pl-lg-1'>
                                            Telefono
                                            <input
                                                type='text'
                                                name='telefono'
                                                {...register('telefono', {
                                                    required: true
                                                })}
                                                className={errors.telefono ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                placeholder='11 1234 5678'
                                                role="presentation"
                                                autoComplete="off"
                                            ></input>
                                            {errors.telefono && <span className='errorMessage'>Ingresa tu telefono.</span>}
                                        </div>


                                    </div>



                                    {checked == true &&
                                        <div>
                                            <h3 className=' pb-1 fo'> Datos para recibir el flete:</h3>


                                            <div className='row formRow '>
                                                <div className='col-12 col-lg-6 p-0 pb-3 pr-lg-1'>
                                                    Direccion
                                                    <input
                                                        type='text'
                                                        name='direccion'
                                                        {...register('direccion', {
                                                            required: true
                                                        })}
                                                        className={errors.direccion ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                        placeholder='Lavalle'
                                                        role="presentation"
                                                        autoComplete="off"
                                                    ></input>
                                                    {errors.direccion && (
                                                        <span className='errorMessage'>Ingresa tu direccion.</span>
                                                    )}
                                                </div>

                                                <div className='col-12 col-lg-2 p-0 pb-3 pr-lg-1 pl-lg-1'>
                                                    Numero
                                                    <input
                                                        type='text'
                                                        name='numero'
                                                        {...register('numero', {
                                                            required: true
                                                        })}
                                                        className={errors.numero ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                        placeholder='6230'
                                                        role="presentation"
                                                        autoComplete="off"
                                                    ></input>
                                                    {errors.numero && (
                                                        <span className='errorMessage'>Ingresa tu numero.</span>
                                                    )}
                                                </div>
                                                <div className='col-12 col-lg-2 p-0 pb-3  pr-lg-1  pl-lg-1'>
                                                    Piso
                                                    <input
                                                        type='text'
                                                        name='piso'
                                                        {...register('piso', {
                                                            required: false
                                                        })}
                                                        className={errors.piso ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                        placeholder='2'
                                                        role="presentation"
                                                        autoComplete="off"
                                                    ></input>
                                                    {errors.piso && (
                                                        <span className='errorMessage'>Ingresa un piso.</span>
                                                    )}
                                                </div>
                                                <div className='col-12 col-lg-2 p-0 pb-3 pl-lg-1'>
                                                    Departamento
                                                    <input
                                                        type='text'
                                                        name='departamento'
                                                        {...register('departamento', {
                                                            required: false
                                                        })}
                                                        className={errors.departamento ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                        placeholder='B'
                                                        role="presentation"
                                                        autoComplete="off"
                                                    ></input>
                                                    {errors.departamento && (
                                                        <span className='errorMessage'>Ingresa un departamento.</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='row formRow '>
                                                <div className='col-12 col-lg-6 p-0 pb-3 pr-lg-1'>
                                                    Localidad
                                                    <input
                                                        type='text'
                                                        name='localidad'
                                                        {...register('localidad', {
                                                            required: true
                                                        })}
                                                        className={errors.localidad ? 'form-control formInput is-invalid' : 'form-control formInput'}
                                                        placeholder='Quilmes'
                                                        role="presentation"
                                                        autoComplete="off"
                                                    ></input>
                                                    {errors.localidad && (
                                                        <span className='errorMessage'>Ingresa tu localidad.</span>
                                                    )}
                                                </div>


                                            </div>

                                        </div>

                                    }


                                </div>




                                <div className='pt-3 pb-3 '>
                                    <div className='row'>
                                        <div className='col-12 p-0 pr-lg-1 col-lg-6 order-2 order-lg-1 text-lg-right text-center'>
                                            <button type="submit" onClick={() => { getEstadoCarrito(0); }} class="btn btn-primary  btn-lg" >Volver</button>
                                        </div>
                                        <div className='col-12 p-0 pl-lg-1 col-lg-6 order-1 order-lg-2 text-lg-left text-center pb-lg-0 pb-2'>
                                            <button type="submit" class="btn btn-primary  btn-lg" disabled={isLoading} >
                                                {isLoading ? <><span class="spinner-border spinner-border-sm"></span> Realizando compra</> : 'Realizar compra'}
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div >


                    </div >
                }


                {
                    estadoCarrito === 2 &&
                    <div className='text-center pl-3 pr-3 pl-lg-5 pr-lg-5 pt-3'>

                        <div className='pb-4'>
                            <span className="fa-layers fa-9x">
                                <FontAwesomeIcon icon={faCircle} color="green" transform="grow-5" />
                                <FontAwesomeIcon icon={faCheck} inverse />
                            </span>
                        </div>

                        <div className='comprastes font-weight-bold pt-2  pb-4'>¡Compra realizada!</div>

                        <div className=''>En breve nos pondremos en contacto con usted.</div>
                        <div>Te enviamos un email con todos los datos de tu compra.</div>
                        <div>Podes llamarnos al 11 4146-3604.</div>
                        <div className='pb-2 d-none'>Tu numero de identificacion es <span className='font-weight-bold'>#{ultimoId}</span>.</div>


                        <div>
                            <Link to="/"><button type="button" class="btn btn-primary  btn-lg mb-3 mt-4" onClick={() => { getEstadoCarrito(0) }}>Finalizar</button></Link>
                        </div>
                        <button onClick={confites} className='d-none'>confites</button>
                    </div>
                }


            </div >
        </>
    )
}

/*

        <div onClick={() => updateByIndex(1)} >+</div>

                            {MiCarrito.map(x => <div>{x.nombre}</div>)
                            }
*/