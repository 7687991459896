import React from 'react';
import { useEffect, useState } from "react";

class Producto {
    constructor(id, nombre, precio, categoria) {
        this.id = id;
        this.nombre = nombre;
        this.precio = precio;
        this.categoria = categoria;
    }
    getProducto() {
        return `<div>id:  ${this.id} <br> Nombre: ${this.nombre} <br> Precio:  ${this.precio} <br> Categoria:  ${this.categoria}  </div >`;
    }
}
class detalle extends Producto {
    constructor(id, nombre, precio, categoria, descripcion) {
        super(id, nombre, precio, categoria);
        this.descripcion = descripcion;
    }
    getDetalle() {
        return `Descripcion: ${this.descripcion}
        sarasa: sarasas.`;
    }
}

const p1 = new detalle(1, 'nombre', 'precio', 3, 'descriptcion');
console.log(p1.getDetalle());


export const Productos = () => {
    const perro = "chiwawa";
    const [gato] = useState('mishi1');
    const animal = "jirafa";




    const getGato = () => {
        //console.log("getGato" + gato);
        //setGato("mishi2");
        console.log("getGato" + gato);
        //return ("gato: {gato}");
    }

    useEffect(() => {
        getGato();
    }, []);

    return (
        <div className='verde'> productos<br></br>
            <br></br>
            {perro}<br></br>
            {gato}
            <br></br>
            {p1.getProducto()}

            {p1.getDetalle()}
            <div dangerouslySetInnerHTML={{ __html: p1.getProducto() }} />



        </div>
    )
}

