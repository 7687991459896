import styled from "styled-components";
import FormLinks from "./components/FormLinks";
import GridLinks from "./components/GridLinks";
import FormCategorias from "./components/FormCategorias";
import GridCategorias from "./components/GridCategorias";
import ListarLinks from "./components/ListarLinks";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import { Layaut } from './components/Layaut';
import { Productos } from './components/Productos';
import { ProductosDetalle } from './components/ProductosDetalle';
import { Productos1 } from './components/Productos1';
import { Home } from './components/Home';
import { NotFound } from './components/NotFound';
import './styles/estilo.css';
import { Outlet, Link } from "react-router-dom";
import { Sucursales } from './components/Sucursales';
import { Carrito } from './components/Carrito';
import { Contacto } from './components/Contacto';
import { useParams } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCartShopping, faPhone, faLocationDot, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import ReactWhatsappButton from "react-whatsapp-button";
import logo from "./imagenes/tiendabrando-logo.svg";

import { ScrollToTop } from "./components/ScrollToTop";
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Helmet, HelmetProvider } from "react-helmet-async";
//import { Helmet } from "react-helmet";
import { SEO } from './components/SEO';


const Container = styled.div`
width: 100%;
height:100%;
display: flex;
flex-direction: column;
align-items: center;
`;
const Title = styled.h2``;
function App() {
  const { idProducto } = useParams();
  console.log("++++++")
  console.log(idProducto)
  const año = "2023";
  const mivariable = "perro14";

  const [links, setLinks] = useState([]);
  const [productosCategorias, setProductosCategorias] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [onEdit1, setOnEdit1] = useState(null);
  const [nombre1, setNombre] = useState(0);
  const [filtro, setFiltro] = useState(0);
  const [filtroOrden, setFiltroOrden] = useState('Menor precio');
  const [ordenar, setOrdenar] = useState('nombre');

  //const [categoria, setCategoria] = useState({ id: 1, nombre: 'Cerámicos' });


  const [idProducto1, setIdProducto1] = useState(idProducto);
  const [ProductoXid, setProductoXid] = useState([]);
  //const [idCategoria, setIdCategoria] = useState(3);
  const [parmsIdProducto, setParmsIdProducto] = useState(null);
  const [MiCarrito, setMiCarrito] = useState([]);
  const [MiCarritoTOTAL, setMiCarritoTOTAL] = useState(0);
  const [productosDescatados, setProductosDestacados] = useState([]);
  const [sucursales, setSucursales] = useState(0);
  const [envioFlete, setEnvioFlete] = useState(false);
  const [checked, setChecked] = useState(false);
  const [paginaActual, setPaginaActual] = useState(1);
  const [notFound, setNotFound] = useState(false);



  const getFiltroOrden = (nuevo) => {
    //alert(nuevoNombre);
    setFiltroOrden(nuevo);
  }
  const getEnvioFlete = (nuevo) => {
    //alert(nuevoNombre);
    setEnvioFlete(nuevo);
  }
  const getMiCarritoTOTAL = (nuevo) => {
    //alert(nuevoNombre);
    var tot = 0;
    MiCarrito.map((MiCarro, index) => tot = tot + MiCarro.precioTOT);
    setMiCarritoTOTAL(tot);
  }
  const getSucursales = (nuevo) => {
    //alert(nuevoNombre);
    setSucursales(nuevo);
  }
  const ObjectLength = () => {
    var length = 0;
    for (var key in MiCarrito) {
      if (MiCarrito.hasOwnProperty(key)) {
        ++length;
      }
    }
    return length;
  }
  const getMiCarrito = (nuevo) => {
    //alert(nuevoNombre);
    setMiCarrito(nuevo);
  }
  const agregarCarrito = () => {
    //alert("sip");
  }
  /*
    const getCategoria = (nuevoNombre) => {
      //alert(nuevoNombre);
      setCategoria(nuevoNombre);
    }*/
  const cambiarNombre = (nuevoNombre) => {
    setNombre(nuevoNombre);
    setOnEdit(null);
  }
  const cambiarFiltro = (nuevoNombre) => {
    setFiltro(nuevoNombre);
    getLinks();
  }
  const cambiarOrdenar = (nuevoNombre) => {
    setOrdenar(nuevoNombre);
    getLinks();
  }
  const getParmsIdProducto = (nuevo) => {
    setParmsIdProducto(nuevo);
    console.log(nuevo);
  }
  const getIdProducto1 = (nuevo) => {
    setIdProducto1(nuevo);
    //console.log(nuevo);
  }

  const getNotFound = (valor) => {
    setNotFound(false);
  };
  /*
    const getIdCategoria = (nuevo) => {
      setIdCategoria(nuevo);
      //console.log(nuevo);
    }
  */

  const getProductosDestacados = async () => {
    //alert("aqui prodcutps lista");
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/productosDestacados/");
      //alert("aqui2");
      setProductosDestacados(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };




  //DUPLICADO
  const getProductosId = async () => {
    //alert("::::::::::" + idProducto1 + " * " + categoria.id);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/producto/" + idProducto1);
      //alert("aqui2");
      console.log("????");
      console.log(res.data);
      console.log(idProducto1);
      //console.log(idCategoria);
      setProductoXid(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };

  const getLinks = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/links/categoria/" + filtro + "/orden/" + ordenar);
      setLinks(res.data);
      //alert("set link");
    } catch (error) {
      toast.error(error);
    }
  };
  const getProductosCategorias = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/categorias/");
      setProductosCategorias(res.data);
      //alert("set link");
    } catch (error) {
      toast.error(error);
    }
  };





  /*
  useEffect(() => {
    getProductos();
    //getProductos();
    //getProductosId();
  }, [filtroOrden]);//setProductos
*/
  useEffect(() => {
    getProductosCategorias();
    getNotFound();
    //alert(notFound)
    //getProductos();
    //getProductosId();
  }, []);//setProductos
  useEffect(() => {
    getMiCarritoTOTAL();
    //getProductos();
    //getProductosId();
  }, [MiCarrito]);//setProductos

  /*
    useEffect(() => {
      getLinks();
    }, [links]);
    useEffect(() => {
      getLinksCategorias();
    }, [linksCategorias]);
  
  */
  return (
    <>
      <HelmetProvider>
        <SEO
          title="Tienda Brando"
          description="Tienda de materiales para la construcción. Cerámicos, porcelanatos, pastinas, pegamentos, griferías, vanitorys, inodoros y bidets. Envíos a domicilio. Teléfono: 11 4146-3604, Dirección: Quilmes, Buenos aires, Argentina. ¡Comprá online ahora!"
          url=""
          canonico="1"
        />
        <Container >
          <div className="container p-0">
            <header>
              <nav class="navbar navbar-expand-lg navbar-dark contenedor-menu" aria-label="Third navbar example">
                <div class="container-fluid">
                  <Link to="/" className="navbar-brand mr-lg-auto"> <img src={logo} alt="TiendaBrando" class="d-inline-block align-text-middle img-fluid logo" /></Link>

                  <button class="navbar-toggler order-2 order-lg-1" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>




                  <div className="collapse navbar-collapse mr-auto order-3 order-lg-2" id="navigation">


                    <ul class="nav-links navbar-nav d-inline-flex">
                      <li class="nav-item">
                        <Link to="/" >Inicio</Link>
                      </li>
                      <li class="nav-item">
                        <Link to="/productos" onClick={() => { setPaginaActual(1); }}>Productos</Link>
                      </li>
                      <li class="nav-item ">
                        <Link to="/sucursales">Sucursales</Link>
                      </li>
                      <li class="nav-item">
                        <Link to="/contacto">Contacto</Link>
                      </li>
                    </ul>

                    {/* 

                   <div id="navigation" className="mr-auto">
 <ul class="nav-links navbar-nav">
                    <li class="nav-item">
                      <Link to="/" onClick={(e) => { getCategoria({ id: 1, nombre: "Ceramicos" }) }}>Inicio</Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/productos">Productos</Link>
                    </li>
                    <li class="nav-item ">
                      <Link to="/sucursales">Sucursales</Link>
                    </li>
                    <li class="nav-item">
                      <Link to="/contacto">Contacto</Link>
                    </li>
                  </ul>

*/}



                  </div>

                  <div className="navbar-text order-1 order-lg-3">

                    <div className="carrito-ico">
                      <Link to="/carrito" className="carrito-ico1"> <FontAwesomeIcon icon={faCartShopping} size="xl" className="ico-col3" />
                        {ObjectLength() != 0 &&
                          <div className="carrito-cantidad">{ObjectLength()}</div>
                        }
                      </Link>
                    </div>




                    {/*(() => {
if (ObjectLength() != 0) {
return (
<>
{ObjectLength()}
</>
);
}
})()*/}

                  </div>



                </div>
              </nav>







            </header>

            <main>
              <Routes>
                <Route path="/" element={<Layaut />}>
                  <Route path="/" element={<Home productos={productosDescatados} getProductos={getProductosDestacados} getIdProducto1={getIdProducto1} productosCategorias={productosCategorias} notFound={notFound} setNotFound={setNotFound} paginaActual={paginaActual} setPaginaActual={setPaginaActual} />} /> {/* productos={productos} getProductos={getProductos} getIdProducto1={getIdProducto1} getCategoria={getCategoria} categoria={categoria} productosCategorias={productosCategorias} */}
                  <Route path="productos" element={<Productos filtroOrden={filtroOrden} getFiltroOrden={getFiltroOrden} getIdProducto1={getIdProducto1} productosCategorias={productosCategorias} paginaActual={paginaActual} setPaginaActual={setPaginaActual} notFound={notFound} setNotFound={setNotFound} />} />{/*getProductos={getProductos}  */}
                  <Route path="productos/:categoria" element={<Productos filtroOrden={filtroOrden} getFiltroOrden={getFiltroOrden} getIdProducto1={getIdProducto1} productosCategorias={productosCategorias} paginaActual={paginaActual} setPaginaActual={setPaginaActual} notFound={notFound} setNotFound={setNotFound} />} />{/*getProductos={getProductos}  */}
                  <Route path="productos/:categoria/:slug" element={<ProductosDetalle setMiCarrito={setMiCarrito} productosCategorias={productosCategorias} notFound={notFound} setNotFound={setNotFound} />} /> {/* productoXid={ProductoXid} getProductosId={getProductosId} getIdProducto1={getIdProducto1} */}
                  <Route path="sucursales" element={<Sucursales sucursales={sucursales} getSucursales={getSucursales} />} />
                  <Route path="contacto" element={<Contacto />} />
                  <Route path="carrito" element={<Carrito checked={checked} setChecked={setChecked} ObjectLength={ObjectLength} envioFlete={envioFlete} getEnvioFlete={getEnvioFlete} MiCarrito={MiCarrito} setMiCarrito={setMiCarrito} MiCarritoTOTAL={MiCarritoTOTAL} getMiCarrito={getMiCarrito} />} />
                  <Route path="*" element={<NotFound notFound={notFound} setNotFound={setNotFound} />} />
                </Route>
              </Routes>
            </main>

            <footer className="p-3  pt-5 pt-sm-0 p-sm-5 mb-4">
              <div className="row">
                <div className="col-12 col-lg-8 p-0">
                  <div className="font-weight-bold pb-2 text-center text-lg-left">CONTÁCTANOS</div>
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <div className="d-inline-flex">
                      <div>
                        <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faLocationDot} /></span><div><span class="font-weight-bold">Dirección:</span> Cno. Gral. Manuel Belgrano 3748. <br></br>Quilmes, Buenos aires, Argentina.</div></div>
                        <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faPhone} /></span><div><span class="font-weight-bold">Teléfono:</span> <span>11 4146-3604</span> <spa>/</spa> <span>4200-1429</span> </div></div>
                        <div className="pb-2 d-flex"><span className="ico-contacto"><FontAwesomeIcon icon={faEnvelope} /></span><div><span class="font-weight-bold">Email:</span> <span className="text-break">Revestimientojazminquilmes@gmail.com</span></div></div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-12 col-lg-4 p-0 pt-4 pt-lg-0">
                  <div className="font-weight-bold pb-2 text-center text-lg-left">SEGUINOS EN</div>
                  <div className="  text-center text-lg-left">
                    <a href="http://www.facebook.com/ceramicasa.solano.1/" target="_blank" className="pr-3"> <FontAwesomeIcon icon={faFacebook} size="3x" className="ico-col1" /></a>
                    <a href="https://www.instagram.com/ceramicos.groupbrando/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="3x" className="ico-col1" /></a>
                  </div>
                </div>
              </div>
              <div class="text-center pt-5 font-weight-bold marca-pie pb-4 pb-md-0">
                Tienda Brando	{new Date().getFullYear()}
              </div>
            </footer>

          </div>
          <ReactWhatsappButton
            countryCode="54"
            phoneNumber="91141463604"
            animated
          />{/*animated*/}
        </Container>
        <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_RIGHT} />
        <ScrollToTop />
      </HelmetProvider>
    </>
  );
}
export default App;




/*

                <div>
                  <div><FontAwesomeIcon icon={faInstagram} className="mr-1" /><a href="https://www.instagram.com/ceramicosbrando" target="_blank" class="link_contacto">Instagram</a></div>
                  <div><FontAwesomeIcon icon={faFacebook} className="mr-1" /><a href="https://www.facebook.com/ceramicosbrando" target="_blank" class="link_contacto">Facebook</a> </div>
                  <div><FontAwesomeIcon icon={faYoutube} className="mr-1" /><a href="https://www.youtube.com/ceramicosbrando" target="_blank" class="link_contacto">Youtube</a> </div>
                </div>




                                <div>
                  <div><FontAwesomeIcon icon={faPhone} /><span class="fw-bold">Móvil:</span> 11 6541 1134</div>
                  <div><FontAwesomeIcon icon={faEnvelope} /> <span class="fw-bold">Email:</span> info@ceramicosbrando.com.ar</div>
                  <div><FontAwesomeIcon icon={faLocationDot} /><span class="fw-bold">Dirección:</span> Quilmes, Buenos Aires, Argentina.</div>
                </div>

*/