import axios from "axios";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
const FormContainer = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px;
`;
const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  width: 140px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  height: 40px;
  background-color:#0b0e11;
  color:white;
`;
const Label = styled.label``;
const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #2c73d2;
  color: white;
  height: 42px;
`;
const FormCategorias = ({ getLinksCategorias, onEdit1, setOnEdit1 }) => {
    const ref = useRef();
    const handleEdit2 = () => {

    };
    useEffect(() => {
        if (onEdit1) {
            document.getElementById("sara").focus();
            const LinksCategorias = ref.current;
            LinksCategorias.nombre.value = onEdit1.nombre;
        }
    }, [onEdit1]);
    const yoyo = () => {
        alert("asasasas");
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const LinksCategorias = ref.current;
        if (
            !LinksCategorias.nombre.value
        ) {
            return toast.warn("Completa todos los campos!");
        }
        if (onEdit1) {
            await axios
                .put(process.env.REACT_APP_URL_API + "/linksCategorias/" + onEdit1.id, {
                    nombre: LinksCategorias.nombre.value,
                })
                .then(({ data }) => toast.success(data))
                .catch(({ data }) => toast.error(data));
        } else {
            await axios
                .post(process.env.REACT_APP_URL_API + "/linksCategorias/", {
                    nombre: LinksCategorias.nombre.value,
                })
                .then(({ data }) => toast.success(data))
                .catch(({ data }) => toast.error(data));
        }
        LinksCategorias.nombre.value = "";
        setOnEdit1(null);
        getLinksCategorias();
    };
    return (
        <div>
            <FormContainer ref={ref} onSubmit={handleSubmit} className="color2 mt-3">
                <InputArea>
                    <Label>Nombre</Label>
                    <Input id="sara" name="nombre" onLoad={e => { console.log("el valor guardado es: " + e.value) }} role="presentation" autoComplete="off" />
                </InputArea>
                <Button type="submit">GUARDAR</Button>
            </FormContainer>
        </div>
    );
};
export default FormCategorias;
