import React from 'react'
import { useEffect } from "react";
export const NotFound = ({ notFound, setNotFound }) => {
    //alert(notFound1);

    /*
    useEffect(() => {
        const handleUnload = () => {
            // Perform actions before the component unloads
            setNotFound(false);
            alert("exit not found")
        };
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, []);
    */
    /*
        useEffect(() => {
            const handleBeforeUnload = (event) => {
                // Perform actions before the component unloads
                event.preventDefault();
                event.returnValue = '';
                setNotFound(false);
                alert("exit not found")
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }, []);*/
    return (
        <div className='pl-3 pr-3 pb-4 pt-4 fondo1'>
            <div className='p-5 text-center '>
                <div className='pt-5 pb-1 e404-1'>Ooops!{notFound}</div>
                <div className='pb-5 e404-2'>Esta página no existe.</div>

            </div>
        </div>
    )
}