import React from 'react';
import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { ProductosListar } from './ProductosListar';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { SEO } from './SEO';
import { NotFound } from './NotFound';

export const Productos = ({ filtroOrden, getFiltroOrden, getIdProducto1, productosCategorias, paginaActual, setPaginaActual, notFound, setNotFound }) => {
    ///const [categoria, setCategoria] = useState(1);
    const { categoria = 'ceramicos' } = useParams();
    const [productos, setProductos] = useState([]);

    //const [NombreCategoria, setNombreCategoria] = useState("x");
    /*
        if (categoriaURL == undefined) {
            alert("vacio")
        } else {
            setCategoria(categoriaURL);
        }*/


    //console.log(paginaActual);

    const nombreCategoria = productosCategorias.filter(x => x.slug == categoria).map(y => { return (y.nombre) });




    //const [paginaActual, setPaginaActual] = useState(1);
    const [rango1, setRango1] = useState(0);
    const [rango2, setRango2] = useState(0);
    var paginaActual1 = 1;



    /*
        const micat = productosCategorias.find(x => {
            return x.id == categoria;
        });
        //console.log(x.id + "/" + categoria)
        console.log("**********************")
        console.log(micat)
        console.log("**********************")
    */
    /*
        const getNombreCategoria = (id) => {
    
            console.log(productosCategorias)
    
            let z = "jachis";//productosCategorias.filter(categoria => categoria.id === id).map(x => { x.nombre })
            setNombreCategoria(z);
            //productosCategorias.filter(categoria => { categoria.id === id }).map(categoria => (categoria.nombre))
        }
    */

    const rango = () => {
        setRango1((paginaActual - 1) * paginascionM);
        setRango2(paginaActual * paginascionM);
        //rango1 = (paginaActual - 1) * paginascionM;
        //rango2 = paginaActual * paginascionM;
    }

    const ScrollTop = () => {
        window.scrollTo(0, 0);
    }

    const sumaPagina = () => {
        paginaActual1 = paginaActual + 1;
        setPaginaActual(paginaActual1);
        //alert("holis" + paginaActual);
        rango();
        ScrollTop();
    }

    const restarPagina = () => {
        paginaActual1 = paginaActual - 1;
        setPaginaActual(paginaActual1);
        rango();
        ScrollTop();
        //alert("holis" + paginaActual);
    }
    /*
    const getSucursales = (nuevo) => {
        //alert(nuevoNombre);
        setSucursales(nuevo);
    }*/

    var length = 0;
    for (var key in productos) {
        if (productos.hasOwnProperty(key)) {
            ++length;
        }
    }
    var registrosTotales = length;


    var paginacionX = 0;
    var paginascionM = 18;//18

    var paginas = 0;

    var i = 0;

    do {//loop
        paginacionX = paginacionX + paginascionM;
        paginas++;
        if (paginacionX >= registrosTotales) {//corta bucle
            i = 1;
        }
    } while (i == 0);
    console.log("!!!!!");
    console.log(paginas);




    const getProductos = async () => {
        //alert("categoria " + categoria);
        try {
            const res = await axios.get(process.env.REACT_APP_URL_API + "/productos/" + categoria + "/" + filtroOrden);
            //alert("aqui2");
            console.log("///////////////////////")
            console.log(res.data)
            console.log("///////////////////////")
            setProductos(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
            setNotFound(false);

        } catch (error) {
            if (error.response.status == 404) {
                //alert("not found")
                //navigate('/producto/' + idProducto);
                setNotFound(true);
            }
            //toast.error(error);
        }
    };


    //const productos1 = productos['productos'];
    //console.log(productos);
    console.log("+++");
    console.log(categoria);

    /*
        useEffect(() => {
            getNombreCategoria(categoria);
        }, [categoria]);
    
    */

    useEffect(() => {
        getProductos();
    }, [categoria]);




    useEffect(() => {
        //alert("cambia rango");
        rango();
        //getProductos();
    }, [rango]);
    /*
        useEffect(() => {
            alert(notFound);
            //setNotFound(false);
        }, []);
    */

    //let filterNumbers = productosCategorias.filter(element => element.id === categoria);



    //console.log(categoria);
    const cambiatetete = (id, categoria) => {
        console.log("cambcatete");
        getIdProducto1(id);
        //getIdCategoria(categoria);
    }

    return (
        notFound == true ?
            <NotFound />
            :
            <>

                <SEO
                    title="Tienda Brando - Productos"
                    description="Cerámicos, porcelanatos, pastinas, pegamentos, griferías, vanitorys, inodoros y bidets. Envíos a domicilio."
                    url="productos"

                />
                <div className='row'>
                    <div className='col-12 col-lg-2 pr-0 pl-0 pt-4 pb-3 fondo4'>

                        <div className='d-flex justify-content-center'>
                            <div>
                                <div className='font-weight-bold pl-0 pl-4 pb-3 text-left'>Categorias</div>
                                <ul className='categoria'>
                                    {productosCategorias.map((item) => (
                                        <li className='pb-3 lh'><Link to={"/productos/" + item.slug} onClick={(e) => { setPaginaActual(1) }}>{item.nombre}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-10 p-3 pt-4 fondo5 '>
                        <div className='p-3 mb-2 fondo6 text-center '>{nombreCategoria && nombreCategoria}</div >
                        {productos.length == 0
                            ?
                            <div class="d-flex justify-content-center m-5">
                                <div className='text-center fondo9 border fondo9 p-3 pl-4 pr-4 p-1  d-inline-flex '>No se encontraron resultados.</div>
                            </div>
                            :
                            <>
                                <div className='d-flex justify-content-end  align-items-center pt-3 pb-0'>
                                    <div className='pr-1'>Ordenar por:</div>
                                    <div>

                                        <div class="dropdown pr-1 ">
                                            <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                {filtroOrden}
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" onClick={() => { getFiltroOrden('Menor precio') }}>Menor precio</a>
                                                <a class="dropdown-item" onClick={() => { getFiltroOrden('Mayor precio') }}>Mayor precio</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className='mt-3 mb-4'>

                                    <nav aria-label="...">
                                        <ul class="pagination justify-content-center">
                                            {paginaActual > 1
                                                && < li className="page-item">
                                                    <a class="page-link" onClick={() => restarPagina()}>&laquo; Anterior</a>
                                                </li>
                                            }
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">{paginaActual}</a>
                                            </li>
                                            <li class="page-item disabled"><a class="page-link" href="#">de {paginas}</a></li>
                                            {paginaActual < paginas
                                                &&
                                                <li class="page-item">
                                                    <a class="page-link" onClick={() => sumaPagina()}>Siguiente &raquo;</a>
                                                </li>

                                            }


                                        </ul >
                                    </nav >


                                </div >

                                <ProductosListar productos={productos} getProductos={getProductos} getIdProducto1={getIdProducto1} rango1={rango1} rango2={rango2} notFound={notFound} setNotFound={setNotFound} productosCategorias={productosCategorias} />



                                <div className='mt-1 mb-5'>

                                    <nav aria-label="...">
                                        <ul class="pagination justify-content-center">
                                            {paginaActual > 1
                                                && < li className="page-item">
                                                    <a class="page-link" onClick={() => restarPagina()}>&laquo; Anterior</a>
                                                </li>
                                            }
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">{paginaActual}</a>
                                            </li>
                                            <li class="page-item disabled"><a class="page-link" href="#">de {paginas}</a></li>
                                            {paginaActual < paginas
                                                &&
                                                <li class="page-item">
                                                    <a class="page-link" onClick={() => sumaPagina()}>Siguiente &raquo;</a>
                                                </li>

                                            }


                                        </ul >
                                    </nav >


                                </div >

                            </>
                        }








                    </div >
                </div >
            </>
    )
}

/*

        {paginaActual}
                {paginas}
                <br></br>
                {rango1}-
                {rango2}<br></br>
                {registrosTotales}


                <div>
                    {
                        productos.map((producto) =>
                            <div>
                                <div>id: {producto.id}</div>
                                <div>nombre: {producto.nombre}</div>
                                <div>precio: {producto.precio}</div>
                                <div> categoria: {producto.categoria}</div>
                                <div><Link to={"/productos/" + producto.id} onClick={() => { cambiatetete(producto.id, producto.categoria) }}>ver mas</Link></div>
                            </div>
                        )
                    }
                </div>
*/