import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
//import { Helmet } from 'react-helmet';
export const SEO = ({ title, description, url, canonico }) => {
    //   const SEO = ({ title, description, url, canonico }) => (
    return (
        <Helmet>
            <meta name="author" content="Marcos Brandolin" />
            <meta name="keywords" content="Cerámicos, porcelanatos, pastinas, pegamentos, griferías, vanitorys, inodoros y bidets." />
            {canonico == "1" &&
                <link rel="canonical" href="https://www.tiendabrando.com.ar/productos" />
            }
            <title>{title}</title>
            <meta name="description"
                content={description} />
            <link rel="icon" href="./favicon.ico" />

            <meta property="og:title" content={title} />
            <meta property="og:description"
                content={description} />
            <meta property="og:image" content="./favicon.ico" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={"https://www.tiendabrando.com.ar/" + url} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description"
                content={description} />
            <meta name="twitter:image" content="./favicon.ico" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    );
};



/*



        <meta name="twitter:creator" content="@diepsteph" />
        <meta name="twitter:site" content="@diepsteph" />


*/